import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, BoxProps } from '@mui/material'
import image from '../images/logos/Logo.svg'

// ----------------------------------------------------------------------

interface LogoProps {
  disabledLink?: boolean
  sx?: BoxProps['sx']
  path?: string
}

export default function Logo({
  disabledLink = false,
  sx,
  path = '/'
}: LogoProps) {
  const logo = (
    <Box
      component="img"
      src={image}
      alt="Velocity Logo" // Provide alternative text for accessibility
      sx={{ width: 'auto', height: '40px', ...sx }} // Make the image responsive
      width="auto" // Explicit width to reduce layout shifts
      height="40px" // Explicit height to reduce layout shifts
    />
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <RouterLink to={path}>{logo}</RouterLink>
}
