import React from 'react'
import Stack from '@mui/material/Stack'
import Section from '../../../components/Section'
import LottieControl from '../../../components/Lottie'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'

const LandingPageIntro: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={4}
      sx={{
        marginX: 0,
        justifyContent: 'space-between',
        alignItems: 'flex-start' // Align text to the top
      }}
    >
      <Section sx={{ textAlign: 'left', flex: 1 }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}
        >
          Innovative <br /> Healthcare <br /> Solutions
        </Typography>
        <Typography variant="body1" color={'#808080'} paragraph>
          Velocity Consulting stands as a beacon of expertise and innovation,
          dedicated to helping independent hospitals navigate the complex
          healthcare landscape. Through collaborative partnerships and a
          relentless commitment to excellence, we empower healthcare
          institutions to achieve sustainable growth and operational efficiency.
        </Typography>
        <Link to="/contact-us" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Learn More
          </Button>
        </Link>
      </Section>
      {!isMobile && !isMediumScreen && (
        <Section>
          <LottieControl />
        </Section>
      )}
    </Stack>
  )
}

export default LandingPageIntro
