import React from 'react'
import {
  Typography,
  // Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper
} from '@mui/material'
import ImageWithText from '../../../components/ImageWithText'
import { ReactComponent as NegotiateSVG } from '../../../images/illustrations/negotiate.svg'
// import { Link as RouterLink } from 'react-router-dom'

const LandingPageServices: React.FC = () => {
  return (
    <ImageWithText
      id="services"
      image={<NegotiateSVG width={400} height={'100%'} />}
      text={
        <>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}
            // color={'secondary'}
          >
            Services
          </Typography>
          <Typography variant="body1" paragraph>
            Offering advisory services at both macro and micro levels within
            administration and contracting, to ensure private hospitals can
            enhance their overall operational effectiveness and contract
            compliance, thereby improving financial performance. The services
            are therefore designed to address the complex challenges facing
            healthcare organizations and support them in achieving their
            strategic objectives.
          </Typography>
          <List
            component={Paper}
            elevation={8}
            sx={{
              padding: 2,
              borderRadius: '16px',
              backgroundImage: 'linear-gradient(to bottom, #ffffff, #f1f1f1)'
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Contract Implementation:</strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Fee for service models</li>
                      <li>
                        Fixed fee models, contract simplification through manual
                        design
                      </li>
                      <li>
                        Global fee models, contract simplification through
                        manual design
                      </li>
                    </ul>
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>
                      Support Hospital Administration Value Chain:
                    </strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Process assessment and workflow implementation</li>
                      <li>Policy and procedure design</li>
                      <li>Training models</li>
                    </ul>
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Funder Market Exposure:</strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    Assist new and established private hospitals to effectively
                    introduce service offerings to the funder market.
                  </Typography>
                }
              />
            </ListItem>
          </List>
          {/* <RouterLink to="/contact-us" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: 2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                // backgroundImage: 'linear-gradient(to bottom, #3f51b5, #283593)',
                transition: '0.3s'
              }}
            >
              Contact Us
            </Button>
          </RouterLink> */}
        </>
      }
    />
  )
}

export default LandingPageServices
