import React, { useState } from 'react'
import Page from '../../components/Page'
import { ReactComponent as NegotiateSVG } from '../../images/illustrations/agreement.svg'
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase'

interface FormData {
  firstName: string
  lastName: string
  email: string
  phone: string
  company: string
  subscribe: boolean
}

const validationSchema: Yup.ObjectSchema<FormData> = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string().required('Phone Number is required'),
  company: Yup.string().required('Company Name is required'),
  subscribe: Yup.boolean().required('Subscription preference is required')
})

const ContactUs: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
    // setValue // Add setValue function from react-hook-form
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      subscribe: false // Set default value of checkbox to false
    }
  })

  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const reCaptchaRef = React.useRef<ReCAPTCHA>(null)

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token)
  }

  const handleSuccessDialog = () => {
    setSubmitted(true) // Set submitted state to true
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setSubmitted(false)
    setDialogOpen(false)
    // Reset the form and reCAPTCHA
    reset({ subscribe: false })
    reCaptchaRef.current?.reset()
    setCaptchaToken(null)
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setSubmitted(true)
    if (captchaToken) {
      try {
        // Save form data to Firestore with timestamp
        await addDoc(collection(db, 'contactForms'), {
          ...data,
          timestamp: serverTimestamp()
        })
        console.log('Form data submitted to Firestore')
        // Show success dialog
        handleSuccessDialog()
      } catch (error) {
        console.error('Error adding document: ', error)
      }
    } else {
      // Handle captcha error
      console.error('Please complete the captcha')
    }
  }

  return (
    <Page title="Contact Us">
      <Grid container spacing={7}>
        <Grid item xs={12} md={6}>
          <Box mb={8}>
            <Typography variant="h3" gutterBottom>
              Get in Touch
            </Typography>
            <Typography variant="body1" gutterBottom>
              We&apos;d love to hear from you! Fill out the form, and our team
              will get back to you as soon as possible.
            </Typography>
          </Box>
          <Box mt={8} mb={8}>
            <NegotiateSVG
              width="100%"
              // height="auto"
              style={{ maxWidth: '400px', margin: '0 auto', height: 'auto' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      fullWidth
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      fullWidth
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="email"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      fullWidth
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="company"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Company Name"
                      fullWidth
                      error={!!errors.company}
                      helperText={errors.company?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="subscribe"
                  control={control}
                  defaultValue={false} // Set default value to false
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          color="primary"
                          checked={field.value}
                        />
                      } // Use field.value to control the checked state
                      label="I consent to receiving occasional marketing updates from Velocity Consulting, including the latest news, industry insights, and product updates."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  You can unsubscribe from these communications at any time. For
                  details on how to manage your preferences and how we protect
                  and respect your privacy, please review our Privacy Policy.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  By clicking submit below, you consent to Velocity Consulting
                  storing and processing your personal information to contact
                  you.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6LeMOacpAAAAABZAQGY1mjNqjaT8G4IaHFEnYRbh"
                  onChange={handleCaptchaChange}
                  aria-label="Captcha"
                />
                {/* Conditionally display CAPTCHA validation error */}
                {submitted && !captchaToken && !dialogOpen && (
                  <Typography variant="body2" color="error" gutterBottom>
                    Please complete the Captcha.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Success Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your form has been submitted successfully! Thank you for reaching
            out to us. We&apos;ll review your message and be in touch shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default ContactUs
