import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Page from '../../components/Page'
import Section from '../../components/Section'
import TeamSection from './sections/TeamSection' // Component to display team members

const AboutUsPage: React.FC = () => {
  return (
    <Page title="About Us">
      <Section>
        <Typography variant="h3" gutterBottom>
          Our Story
        </Typography>
        <Typography variant="body1">
          The objective behind establishing Velocity Consulting in January 2019,
          was to leverage 26 years of private hospital experience and healthcare
          knowledge, to empower independant private hospitals to thrive in an
          ever changing, competitive healthcare landscape, while delivering
          exceptional patient care.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h3" gutterBottom>
          Our Team
        </Typography>
        <Typography variant="body1">
          Meet the dedicated individuals who drive our company forward.
        </Typography>
        <TeamSection /> {/* Component to display team members */}
      </Section>

      <Section>
        <Typography variant="h3" gutterBottom>
          Our Vision
        </Typography>
        <Typography variant="body1">
          To pioneer industry excellence by leveraging operational efficiencies,
          fostering innovation, and cultivating a culture of continuous
          improvement, thus securing enduring competitive advantage.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h3" gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1">
          Our mission is to empower private independent hospital groups and
          facilities by providing comprehensive insights into the evolving
          healthcare landscape, facilitating adaptability, and fostering a
          focused approach to thrive amidst constant change. Through strategic
          guidance and innovative solutions, we aim to equip our clients with
          the knowledge and resources necessary to navigate challenges and
          achieve sustainable success in the dynamic healthcare environment.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h3" gutterBottom>
          Join Us
        </Typography>
        <Typography variant="body1">
          Interested in joining our team? Check out our current job openings.
        </Typography>
        <Link to="/careers" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            View Openings
          </Button>
        </Link>
      </Section>
    </Page>
  )
}

export default AboutUsPage
