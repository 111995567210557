// ImageTextView.tsx
import React, { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import Section from './Section'
import { useMediaQuery, useTheme } from '@mui/material'

interface ServicesProps {
  image?: ReactNode // Fragment to be rendered as the image
  text: ReactNode // Fragment to be rendered as the text
  imageOnLeft?: boolean // Optional prop to indicate whether the image should be on the left side
  id?: string
}

const ImageTextView: React.FC<ServicesProps> = ({
  image,
  text,
  imageOnLeft = true,
  id = ''
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // Check if screen size is mobile

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'} // Change direction to column on mobile view
      spacing={4}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 4
      }}
      id={id}
    >
      {!isMobile &&
        imageOnLeft && ( // Render image on the left side if not in mobile view and imageOnLeft prop is true
          <Section>{image}</Section>
        )}
      <Section sx={{ textAlign: isMobile ? 'left' : 'start' }}>{text}</Section>
      {!isMobile &&
        !imageOnLeft && ( // Render image on the right side if not in mobile view and imageOnLeft prop is false
          <Section>{image}</Section>
        )}
    </Stack>
  )
}

export default ImageTextView
