import React from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper
} from '@mui/material'
import ImageWithText from '../../../components/ImageWithText'
import { ReactComponent as NegotiateSVG } from '../../../images/illustrations/candidate.svg'

const LandingPageTraining: React.FC = () => {
  return (
    <ImageWithText
      id="training"
      image={<NegotiateSVG width={400} height={'100%'} />}
      text={
        <>
          <Typography variant="h3" marginBottom={2}>
            Training
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            We understand the critical role that ongoing education plays in
            ensuring the success of hospital staff at every level. That&apos;s
            why we offer tailored training programs designed to empower your
            entire team, from pre-admission to credit control.
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Our comprehensive sessions are carefully curated to address the
            specific needs and challenges faced by hospital administration, with
            a focus on boosting efficiency, enhancing performance, elevating the
            standard of service to patients ensuring a positive patient
            experience is achieved.
          </Typography>
          <List
            component={Paper}
            elevation={8}
            sx={{
              padding: 2,
              borderRadius: '16px',
              backgroundImage: 'linear-gradient(to bottom, #ffffff, #f1f1f1)'
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Pre-Admission and Reception:</strong> Balancing
                    patient administration and patient experience
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Confirmations:</strong> Understanding validation and
                    confirmation
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Case Management:</strong> Time Management and risk
                    management principles
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Billing:</strong> Hospital Industry standards and
                    billing principles
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Credit Control:</strong> Basics on Hospital
                    contracting and credit control functions
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </>
      }
    />
  )
}

export default LandingPageTraining
