import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'
import Container from '@mui/material/Container'

interface PageProps extends BoxProps {
  title?: string
  meta?: React.ReactNode
}

const Page = forwardRef<HTMLDivElement, PageProps>(function Page(
  { children, title = '', meta, ...other },
  ref
) {
  return (
    <>
      <title>{`${title} | Velocity Consulting`}</title>
      {meta}

      <Box ref={ref} {...other}>
        <Container maxWidth="lg">{children}</Container>
      </Box>
    </>
  )
})

Page.displayName = 'Page'

export default Page
