import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box' // Import Box component
import Page from '../../components/Page'
import Section from '../../components/Section'
import NoJobsSVG from '../../images/illustrations/careers.svg' // Import SVG image

// Define interface for job listing
interface JobListing {
  id: number
  title: string
  description: string
}

// Sample job data
const jobListings: JobListing[] = [
  // {
  //   id: 1,
  //   title: 'Position 1',
  //   description: 'Description of position 1'
  // },
  // {
  //   id: 2,
  //   title: 'Position 2',
  //   description: 'Description of position 2'
  // }
  // Add more job listings as needed
]

const CareersPage: React.FC = () => {
  return (
    <Page title="Careers">
      <Section>
        {jobListings.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={4}
          >
            <img
              src={NoJobsSVG}
              alt="No Jobs"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
            <Typography variant="h4" gutterBottom marginTop={8}>
              Sorry, there are currently no job openings.
            </Typography>
            <Typography variant="body1">
              Please check back later for updates or contact us for more
              information.
            </Typography>
          </Box>
        ) : (
          <div>
            <Typography variant="h3" gutterBottom>
              Current Job Openings
            </Typography>
            <Grid container spacing={3}>
              {jobListings.map((job) => (
                <Grid item xs={12} sm={6} md={4} key={job.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        {job.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {job.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link
                        to={`/apply/${job.id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button size="small" color="primary">
                          Apply Now
                        </Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Section>
    </Page>
  )
}

export default CareersPage
