import { initializeApp } from 'firebase/app'
import { FirebaseApp } from 'firebase/app'
import { getFirestore, Firestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyD0Qd8g-z0G8hOMXSXzl8m_8sz22ccLtIU',
  authDomain: 'velocityconsulting.firebaseapp.com',
  projectId: 'velocityconsulting',
  storageBucket: 'velocityconsulting.appspot.com',
  messagingSenderId: '332931788408',
  appId: '1:332931788408:web:d52c6427adc7d654e9c78d',
  measurementId: 'G-HKCY3DEDPQ'
}

const app: FirebaseApp = initializeApp(firebaseConfig)
export const db: Firestore = getFirestore(app)
export const analytics = getAnalytics(app)
