// Section.tsx
import React, { ReactNode } from 'react'
import { styled, SxProps } from '@mui/system'

interface SectionProps {
  children: ReactNode
  sx?: SxProps
}

const SectionRoot = styled('section')(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(8)
}))

const Section: React.FC<SectionProps> = ({ children, sx }) => {
  return <SectionRoot sx={sx}>{children}</SectionRoot>
}

export default Section
