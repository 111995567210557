import React, { useState } from 'react'
import { Card, CardContent, Box, Typography, Grow } from '@mui/material'
import { Link } from 'react-router-dom'

interface InfoCardProps {
  icon: React.ReactNode
  title: string
  description: string
  timeout?: number
  to?: string // Added 'to' prop for the Link component
}

const InfoCard: React.FC<InfoCardProps> = ({
  icon,
  title,
  description,
  timeout = 0,
  to = ''
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const cardStyle = {
    maxWidth: 345,
    height: '420px',
    padding: '12px',
    boxShadow: isHovered
      ? '0 16px 24px 0 rgba(0,0,0,0.7)'
      : '0 4px 8px 0 rgba(0,0,0,0.5)',
    backgroundImage: 'linear-gradient(360deg, #002366 35%, #6699CC 120%)',
    transition:
      'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, z-index 0.3s ease-in-out',
    transform: isHovered ? 'scale(1.05)' : 'none',
    zIndex: isHovered ? 1 : 0
  }

  // Determine if the 'to' prop contains a hash
  const isHashLink = to && to.includes('#')

  // Smooth scrolling with offset
  const handleClick = () => {
    if (isHashLink) {
      const targetSectionId = to!.substring(1) // Remove the '#' from the link
      const target = document.getElementById(targetSectionId)
      const headerHeight = document.getElementById('header')?.clientHeight || 0 // Get the height of the header
      if (target) {
        window.scrollTo({
          top: target.offsetTop - headerHeight,
          behavior: 'smooth'
        })
      }
    }
  }

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout }}>
      {/* Use Link instead of HashLink */}
      <Link
        to={to}
        onClick={handleClick} // Call handleClick for hash links
        style={{ textDecoration: 'none' }}
      >
        <Card
          sx={cardStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: '48px' }}
          >
            {icon}
          </Box>
          <CardContent sx={{ padding: 2 }}>
            <Typography gutterBottom variant="h5" component="div" color="white">
              {title}
            </Typography>
            <Typography variant="body2" color="white">
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grow>
  )
}

export default InfoCard
