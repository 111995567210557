import React from 'react'
import { Grid, Typography, Card, CardContent, Avatar } from '@mui/material'
import { styled } from '@mui/system'

// Define a type for team members
interface TeamMember {
  id: number
  name: string
  position: string
  bio: string
  src?: string
}

// Sample team members data (replace with your actual data)
const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: 'Susan Smit',
    position: 'Founder and Managing Director',
    bio: 'I am a seasoned healthcare professional with over two decades of hands-on experience in the private hospital industry. With a relentless drive for success, I continue to shape the future of healthcare administration.',
    src: require('../../../images/profile/susan.jpeg')
  }
]

const CardContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left', // Align text to the left
  height: '100%',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: `${(theme as any)?.shadows[3]} !important` // Access theme.shadows directly
  }
}))

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: theme?.spacing(12),
  height: theme?.spacing(12),
  marginBottom: theme?.spacing(2)
}))

const TeamSection: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {teamMembers.map((member) => (
        <Grid item key={member.id} xs={12} sm={6} md={4}>
          <CardContainer>
            <CardContent>
              <AvatarStyled alt={member.name} src={member.src} />
              <Typography variant="h5" gutterBottom>
                {member.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {member.position}
              </Typography>
              <Typography variant="body2">{member.bio}</Typography>
            </CardContent>
          </CardContainer>
        </Grid>
      ))}
    </Grid>
  )
}

export default TeamSection
