import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Logo from '../../../components/Logo'
import { Link } from 'react-router-dom'
import pages from './pages.json'

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleHashLinkClick = (hashLink: string) => {
    const targetSectionId = hashLink.substring(2) // Remove the '#' from the link
    const target = document.getElementById(targetSectionId)
    const headerHeight = document.getElementById('header')?.clientHeight || 0 // Get the height of the header
    if (target) {
      window.scrollTo({
        top: target.offsetTop - headerHeight,
        behavior: 'smooth'
      })
    }
    // handleCloseNavMenu() // Close the menu after clicking on a hash link
  }

  return (
    <AppBar
      id={'header'}
      style={{
        border: 0,
        boxShadow: 'none'
      }}
      position="fixed"
      sx={{
        background: '#002366'
      }}
    >
      <Box sx={{ mr: 0, ml: 0 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ mr: 2 }}>
              <Logo />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'right'
              }}
            >
              {pages.map((page) => (
                <Button
                  disableRipple
                  key={page.name}
                  component={Link}
                  to={page.link}
                  sx={{
                    my: 0,
                    color: 'white',
                    display: 'block',
                    textRendering: 'optimizeLegibility',
                    fontWeight: 500,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    },
                    fontSize: '20px'
                  }}
                  onClick={() => {
                    if (page.link.includes('#')) {
                      handleHashLinkClick(page.link)
                    }
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'right'
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: 'white' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <Link
                    to={page.link}
                    key={page.link}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <MenuItem
                      key={page.name}
                      onClick={() => {
                        if (page.link.includes('#')) {
                          handleHashLinkClick(page.link)
                        }
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  )
}
export default Header
