import React from 'react'
import Grid from '@mui/material/Grid'
import { ReactComponent as NegotiateSVG } from '../../../images/illustrations/negotiate.svg'
import { ReactComponent as ProductSVG } from '../../../images/illustrations/product_iteration.svg'
import { ReactComponent as CandidateSVG } from '../../../images/illustrations/candidate.svg'
import InfoCard from '../../../components/InfoCard'

const Summary: React.FC = () => {
  return (
    <Grid
      container
      spacing={7} // Reduced spacing for better mobile view
      justifyContent="center" // Center the grid items horizontally
      alignItems="center" // Center the grid items vertically
      marginBottom={8}
    >
      <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
        <InfoCard
          icon={<NegotiateSVG height={150} width={150} />}
          title="Services"
          description="Our services aim to provide tailored solutions to support hospitals in achieving their specific goals and objectives efficiently and effectively."
          to="#services"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
        <InfoCard
          icon={<ProductSVG height={150} width={150} />}
          title="Products"
          description="We've crafted digital workbooks designed to streamline hospital staff's day-to-day tasks, boosting efficiency and productivity across the board."
          timeout={1000}
          to="#products"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
        <InfoCard
          icon={<CandidateSVG height={150} width={150} />}
          title="Training"
          description="We offer tailored training programs for the hospital administration value chain, from pre-admission to credit control. Our sessions aim to boost efficiency, enhance performance, and elevate the standard of care."
          timeout={1500}
          to="#training"
        />
      </Grid>
    </Grid>
  )
}

export default Summary
