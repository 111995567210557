import React from 'react'
import { styled } from '@mui/material/styles'
import Header from './sections/Header'
import Footer from './sections/Footer'
import { Outlet } from 'react-router-dom'

const APP_BAR_MOBILE = 64

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh' // Ensure the root element takes the full height of the viewport
})

const MainStyle = styled('div')(({ theme }) => ({
  flex: '1', // Grow to fill remaining space
  overflow: 'auto',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10)
}))

export default function DashboardLayout() {
  return (
    <RootStyle>
      <Header />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <Footer />
    </RootStyle>
  )
}
