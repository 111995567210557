import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Typography,
  Container,
  Box,
  IconButton,
  Stack,
  Grid
} from '@mui/material'
import { Facebook, LinkedIn, Phone, Email } from '@mui/icons-material' // Import icons
import Logo from '../../../components/Logo'

const FooterStyle = styled('footer')({
  backgroundColor: '#333',
  color: '#fff',
  padding: '20px 0',
  textAlign: 'center'
})

const SocialLinks = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '20px'
})

const Footer: React.FC = () => {
  return (
    <FooterStyle>
      <Container maxWidth="lg">
        <Grid container spacing={7} justifyContent={'space-between'}>
          {/* Left side: Logo */}
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="flex-start"
              style={{ marginBottom: '20px' }}
            >
              <Logo />
            </Stack>
            {/* <Stack
              direction="row"
              spacing={2}
              alignItems="flex-start"
              maxWidth={'60%'}
            >
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                Unlock the potential of your healthcare solutions with our
                expert consultancy.
              </Typography>
            </Stack> */}
          </Grid>

          {/* Right side: Contact Us and Social Links */}
          <Grid item xs={12} md={4}>
            <Stack spacing={2} alignItems={'flex-end'}>
              <Typography variant="h4" gutterBottom id="contact">
                Contact Us
              </Typography>
              <Stack direction="row" alignItems="center">
                <Phone style={{ marginRight: '8px' }} />
                <Typography variant="body1" style={{ textAlign: 'left' }}>
                  +27 71 682 0068
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Email style={{ marginRight: '8px' }} />
                <Typography variant="body1" style={{ textAlign: 'left' }}>
                  susan@velco.co.za
                </Typography>
              </Stack>
              <SocialLinks>
                <IconButton
                  size="large"
                  disableRipple
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                  style={{ color: 'white', padding: 0, margin: 0 }}
                >
                  <LinkedIn style={{ fontSize: '32px' }} />
                </IconButton>
                <IconButton
                  size="large"
                  disableRipple
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                  style={{ color: 'white', marginLeft: '4px' }}
                >
                  <Facebook style={{ fontSize: '32px' }} />
                </IconButton>
              </SocialLinks>
            </Stack>
          </Grid>
        </Grid>

        {/* Bottom section: Copyright */}
        <Typography variant="body1" component="p" marginTop="20px">
          &copy; {new Date().getFullYear()} Velocity Consulting (Pty)Ltd
        </Typography>
        <Typography variant="body1" component="p">
          All rights reserved.
        </Typography>
      </Container>
    </FooterStyle>
  )
}

export default Footer
