import React from 'react'
import Page from '../../components/Page'
import LandingPageIntro from './sections/Intro'
import Summary from './sections/Summary'
import LandingPageServices from './sections/Services'
import LandingPageProducts from './sections/Products'
import LandingPageTraining from './sections/Training'
// import LandingPageTestimonials from './sections/Testimonials' // Import the new component

import { useSpring, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'

const AnimatedPage = animated(Page)

const LandingPage = () => {
  const pageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { tension: 0, friction: 1000 }
  })

  const [servicesRef, servicesInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const [productsRef, productsInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const [trainingRef, trainingInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const servicesProps = useSpring({
    opacity: servicesInView ? 1 : 0,
    from: { opacity: 0 }
  })

  const productsProps = useSpring({
    opacity: productsInView ? 1 : 0,
    from: { opacity: 0 }
  })

  const trainingProps = useSpring({
    opacity: trainingInView ? 1 : 0,
    from: { opacity: 0 }
  })

  return (
    <AnimatedPage style={pageProps} title="Welcome">
      <LandingPageIntro />
      <Summary />
      <div ref={servicesRef}>
        <animated.div style={servicesProps}>
          <LandingPageServices />
        </animated.div>
      </div>
      <div ref={productsRef}>
        <animated.div style={productsProps}>
          <LandingPageProducts />
        </animated.div>
      </div>
      <div ref={trainingRef}>
        <animated.div style={trainingProps}>
          <LandingPageTraining />
        </animated.div>
      </div>
      {/* <LandingPageTestimonials /> */}
    </AnimatedPage>
  )
}

export default LandingPage
