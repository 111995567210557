import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../images/illustrations/health_shield.json'

const LottieControl = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  }

  return (
    <div>
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  )
}

export default LottieControl
