import React, { useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import LandingPage from '../pages/LandingPage'
import NotFound from '../pages/Page404'
// import DefaultLayout from '../layouts/basic'
import DashboardLayout from '../layouts/landing'
import ContactUsPage from '../pages/ContactUs'
import AboutUsPage from '../pages/AboutUs'
import { useLocation } from 'react-router-dom'
import CareersPage from '../pages/Careers'

function Router() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const publicRoutes = [
    { path: '/', element: <LandingPage /> },
    { path: '/contact-us', element: <ContactUsPage /> },
    { path: '/about-us', element: <AboutUsPage /> },
    { path: '/careers', element: <CareersPage /> }
    // { path: '/test', element: <LandingPage /> }
  ]

  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: publicRoutes
    },
    {
      path: '/404',
      element: <NotFound />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    }
  ])
}

export default Router
