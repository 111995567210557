import React from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper
} from '@mui/material'
import ImageWithText from '../../../components/ImageWithText'
import { ReactComponent as NegotiateSVG } from '../../../images/illustrations/product_iteration.svg'

const LandingPageProducts: React.FC = () => {
  return (
    <ImageWithText
      id="products"
      imageOnLeft={false}
      image={<NegotiateSVG width={400} height={'100%'} />}
      text={
        <>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}
          >
            Products
          </Typography>
          <Typography variant="body1" paragraph>
            Information is sourced from the public domain or shared by industry
            partners with permission to share. We do not sell the information
            itself. Instead, our fees cover the collation of this information
            into easily referenced workbooks with quick look-up functionality.
            The workbooks are aimed to offer hospital administration staff quick
            access to critical information from the onset.
          </Typography>
          <Typography variant="body1" paragraph>
            Our offered Administration Policies are industry benched general
            documents, allowing hospitals to customize these documents to fit
            their internal processes.
          </Typography>
          <List
            component={Paper}
            elevation={8}
            sx={{
              padding: 2,
              borderRadius: '16px',
              backgroundImage: 'linear-gradient(to bottom, #ffffff, #f1f1f1)'
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>
                      {new Date().getFullYear()} Medical Scheme Information
                      Workbook
                    </strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    The workbook offers a summary of specific hospital benefits
                    for the specific benefit year critical to hospital services,
                    across all schemes and options. It provides Day Surgery
                    Network co-payments by Scheme, option, and procedure. It
                    also provides Procedural co-payments by Scheme, option, and
                    procedure.
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>
                      {new Date().getFullYear()} Hospital Network Workbook
                    </strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    The Hospital Network list includes all known Medical Scheme
                    Hospital network types and can be customized for your
                    hospital or group of hospitals.
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1" component="div">
                    <strong>Administration Policy Design</strong>
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="div">
                    We offer industry benched administration policies specific
                    to:
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={
                  <Typography variant="body2" component="div">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Pre-Admission</li>
                      <li>Reception</li>
                      <li>Confirmation</li>
                      <li>Case Management</li>
                      <li>Billing</li>
                      <li>Credit Control</li>
                      <li>Filing</li>
                    </ul>
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </>
      }
    />
  )
}

export default LandingPageProducts
